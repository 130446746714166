import { InviteStatus } from '@capturi/core'
import { useOrganization } from '@capturi/stores'
import constate from 'constate'
import React, { useMemo } from 'react'
import useSWR from 'swr'

import { UserResponse } from '../../../types'

type SearchFilterValue = string
type SearchFilterActions = {
  setFilter: (filter: string) => void
  clearFilter: () => void
}
function useSearchFilter(
  initialFilter: string,
): [SearchFilterValue, SearchFilterActions] {
  const [filter, setFilter] = React.useState(initialFilter)
  const clearFilter = (): void => setFilter('')
  return [
    filter,
    {
      setFilter,
      clearFilter,
    },
  ]
}

type UserInvitation = {
  users: UserResponse[]
  filteredUsers: UserResponse[]
  selectedUsers: UserResponse[]
  selectedUserIds: Set<string>
  setSelectedUserIds: (users: Set<string>) => void
  clearSelectedUserIds: () => void
  filter: SearchFilterValue
} & SearchFilterActions

const emptyArray: UserResponse[] = []

export default function useUserInvitation(): UserInvitation {
  const { conversationLanguage } = useOrganization()
  const compare = Intl.Collator(conversationLanguage).compare

  // Selected user ids
  const [selectedUserIds, setSelectedUserIds] = React.useState(
    new Set<string>(),
  )
  // Search filter
  const [filter, { setFilter, clearFilter }] = useSearchFilter('')

  const clearSelectedUserIds = (): void => {
    setSelectedUserIds(new Set())
  }

  const { data } = useSWR<{ users: UserResponse[] }>(
    'authentication/users?includeDeleted=false',
  )

  const allUsers = useMemo(() => data?.users || emptyArray, [data?.users])
  const usersMap = useMemo(
    () => new Map(allUsers.map((u) => [u.id, u])),
    [allUsers],
  )
  // Users filtered by InviteStatus !== accepted
  const users = React.useMemo(() => {
    return allUsers.filter(
      (x) => x.inviteStatus !== InviteStatus.accepted && x.isDeleted !== true,
    )
  }, [allUsers])

  // Users filtered by search query
  const filteredUsers = React.useMemo(() => {
    if (filter.length === 0) {
      return users
    }
    const regex = new RegExp(filter, 'gi')
    return users.filter((x) => x.name.match(regex))
  }, [users, filter])

  // Selected users as user objects
  const selectedUsers = React.useMemo(() => {
    return [...selectedUserIds]
      .reduce<UserResponse[]>((memo, id) => {
        const user = usersMap.get(id)
        if (user) {
          memo.push(user)
        }
        return memo
      }, [])
      .sort((a, b) => compare(a.name, b.name))
  }, [compare, selectedUserIds, usersMap])

  return {
    users,
    filteredUsers,
    selectedUsers,
    selectedUserIds,
    setSelectedUserIds,
    clearSelectedUserIds,
    filter,
    setFilter,
    clearFilter,
  }
}

const [UserInvitationProvider, useUserInvitationContext] =
  constate(useUserInvitation)

export { UserInvitationProvider, useUserInvitationContext }
