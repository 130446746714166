import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { Button } from '@capturi/ui-components'
import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import { isBefore } from 'date-fns'
import { useDeletedUsers } from 'pages/Organization/hooks/useDeletedUserActions'
import { useUserActions } from 'pages/Organization/hooks/useUserActions'
import { useUsers } from 'pages/Organization/hooks/useUsers'
import React, { useState } from 'react'
import { MdClose, MdSearch } from 'react-icons/md'

import AgentsTable from '../../components/AgentsTable'
import { TableWrapper, TableWrapperHeader } from '../../components/TableWrapper'
import { Team, UserWithDeletionData } from '../../types'
import Filter, { DateFilterKey } from '../Filter'
import InviteUsersModal from './InviteUsersModal/InviteUsersModal'

const Agents: React.FC = () => {
  const { data: teams } = useQuery<Team[]>({
    queryKey: ['authentication/organization/teams'],
  })
  const currentUser = useCurrentUser()
  const { patchUser, inviteUser, unassignUser } = useUserActions()
  const { cancelUserDeletion } = useDeletedUsers()
  const { hideInviteOptions } = useFeatureFlags()
  const inviteUsersModal = useDisclosure()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const { users } = useUsers()
  const [dateFilter, setDateFilter] = useState<{
    key: DateFilterKey
    date: Date
  } | null>(null)

  if (!(users && teams)) return null

  const filterFunction = (u: UserWithDeletionData): boolean => {
    if (u.inviteStatus === 'accepted' || u.inviteStatus === 'sent') return false

    if (dateFilter?.key === 'mostRecentConversation') {
      if (u.mostRecentConversation == null) return false
      return isBefore(u.mostRecentConversation, dateFilter.date)
    }
    if (dateFilter?.key === 'lastTokenRefresh') {
      if (u.lastTokenRefresh == null) return false
      return isBefore(u.lastTokenRefresh, dateFilter.date)
    }

    if (u.name.toLowerCase().includes(searchTerm.toLowerCase())) return true
    if (u.email.toLowerCase().includes(searchTerm.toLowerCase())) return true
    if (u.role.includes(searchTerm)) return true
    if (u.team?.name.toLowerCase().includes(searchTerm.toLowerCase()))
      return true
    return false
  }

  const activeUsers = users.filter(filterFunction)

  return (
    <TableWrapper>
      <TableWrapperHeader
        title={t`Agents`}
        tooltip={t`People with conversations but without access to Capturi`}
      >
        <Filter
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
          filterOptions={['mostRecentConversation']}
        />

        <InputGroup width="30%" maxW="200px" size="sm">
          <InputLeftElement pointerEvents="none">
            <Icon fontSize="14px" as={MdSearch} />
          </InputLeftElement>
          <Input
            placeholder={t`Search...`}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            value={searchTerm}
            borderColor="gray.200"
          />
          {searchTerm && searchTerm.length > 0 ? (
            <InputRightElement cursor="pointer">
              <Icon
                fontSize="14px"
                as={MdClose}
                onClick={() => setSearchTerm('')}
              />
            </InputRightElement>
          ) : null}
        </InputGroup>
        {!hideInviteOptions && (
          <>
            <InviteUsersModal
              isOpen={inviteUsersModal.isOpen}
              onClose={inviteUsersModal.onClose}
            />
            <Button primary onClick={inviteUsersModal.onOpen} size="sm">
              <Trans>Send invite</Trans>
            </Button>
          </>
        )}
      </TableWrapperHeader>
      <AgentsTable
        agents={activeUsers}
        currentUser={currentUser}
        teams={teams}
        inviteUser={inviteUser}
        patchUser={patchUser}
        unassignUser={unassignUser}
        cancelUserDeletion={cancelUserDeletion}
      />
    </TableWrapper>
  )
}

export default Agents
