import analytics from '@capturi/analytics'
import { Modal, ModalOverlay, ModalProps } from '@capturi/ui-components'
import React from 'react'
import SendInvitationsView from './SendInvitationsView'
import ShareLinkView from './ShareLinkView'
import { UserInvitationProvider } from './useUserInvitation'

type Props = Omit<ModalProps, 'children'>

const InviteUsersModal: React.FC<Props> = (props) => {
  const { isOpen, onClose } = props

  const [view, setView] = React.useState<'SendInvitations' | 'ShareLink'>(
    'SendInvitations',
  )

  React.useEffect(() => {
    if (isOpen === false) {
      // Reset view
      setView('SendInvitations')
    }
  }, [isOpen])

  React.useEffect(() => {
    if (isOpen) {
      analytics.modalview(`InviteUsersModal_${view}`)
    }
  }, [view, isOpen])

  return (
    <Modal scrollBehavior="inside" {...props}>
      <ModalOverlay>
        <UserInvitationProvider>
          {view === 'SendInvitations' ? (
            <SendInvitationsView
              onClose={onClose}
              onGoToShareLinkView={() => setView('ShareLink')}
            />
          ) : (
            <ShareLinkView
              onClose={onClose}
              onGoBack={() => setView('SendInvitations')}
            />
          )}
        </UserInvitationProvider>
      </ModalOverlay>
    </Modal>
  )
}

export default InviteUsersModal
