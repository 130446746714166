import request, { generateImageUrl } from '@capturi/request'
import {
  List,
  ListItem,
  ListItemElementLeft,
  ListItemElementRight,
  ListItemText,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalProps,
  PageLoader,
  useToast,
} from '@capturi/ui-components'
import {
  Avatar,
  Box,
  BoxProps,
  Flex,
  IconButton,
  Link,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdArrowBack, MdContentCopy } from 'react-icons/md'
import { useCopyToClipboard } from 'react-use'

import {
  logCopyAllShareLinks,
  logCopySingleShareLink,
  logGenerateShareLinks,
} from './events'
import { useUserInvitationContext } from './useUserInvitation'
import {
  formatInvitationLink,
  generatePreformattedShareLinkOverview,
} from './utils'

type UserCode = {
  userId: string
  code?: string
}

function useInvitationCodes(
  userIds: Set<string>,
): [{ [key: string]: string }, boolean] {
  const [state, setState] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    async function fetchInvitationCode(userId: string): Promise<UserCode> {
      const res: UserCode = { userId }
      try {
        const resp = await request.get<{ code: string }>(
          `authentication/user/${userId}/invite`,
        )
        res.code = resp.code
      } catch (_e) {
        // nevermind, return res
      }
      return res
    }

    async function fetchInvitationCodes(): Promise<void> {
      setIsLoading(true)
      /*
        Fetch invitation codes for all users
        TODO: add an API endpoint which resolve invitation codes for multiple users
      */
      const promises = [...userIds].map((id) => fetchInvitationCode(id))
      const invitations = await Promise.all(promises)
      const userCodeMap = invitations.reduce<{
        [key: string]: string | undefined
      }>((memo, invite) => {
        memo[invite.userId] = invite.code
        return memo
      }, {})
      setState(userCodeMap)
      setIsLoading(false)
    }
    logGenerateShareLinks(userIds.size)
    fetchInvitationCodes()
  }, [userIds])
  return [state, isLoading]
}

type Props = {
  onGoBack: () => void
} & BoxProps &
  Pick<ModalProps, 'onClose'>

const ShareLinkView: React.FC<Props> = ({ onGoBack }) => {
  const { selectedUsers, selectedUserIds } = useUserInvitationContext()
  const [, copy] = useCopyToClipboard()
  const toast = useToast()
  const [invitationCodes, isLoading] = useInvitationCodes(selectedUserIds)

  const handleCopyAll = (): void => {
    logCopyAllShareLinks()
    const str = generatePreformattedShareLinkOverview(
      selectedUsers,
      invitationCodes,
    )
    copy(str)
    toast({
      title: t`Copied to clipboard`,
    })
  }

  return (
    <ModalContent>
      <ModalHeader>
        <IconButton
          size="sm"
          isRound
          icon={<MdArrowBack />}
          aria-label={t`Go back`}
          onClick={onGoBack}
          mr={4}
        />
        <Trans>Share invitation links</Trans>
      </ModalHeader>
      <ModalCloseButton />
      <Box px={6}>
        <Flex justify="flex-end" color="textMuted" fontSize="sm">
          <Link onClick={handleCopyAll}>
            <Trans>Copy all</Trans>
          </Link>
        </Flex>
      </Box>
      <ModalBody position="relative">
        <List>
          {selectedUsers.map((user) => (
            <ListItem key={user.id}>
              <ListItemElementLeft>
                <Avatar
                  size="sm"
                  src={generateImageUrl(user.profileImage?.key, {
                    size: 32,
                  })}
                  name={user.name}
                />
              </ListItemElementLeft>
              <ListItemText as="div">
                <Text as="div" noOfLines={1} wordBreak="break-all">
                  {user.name}
                </Text>
                <Text
                  as="div"
                  noOfLines={1}
                  wordBreak="break-all"
                  fontSize="sm"
                >
                  {invitationCodes[user.id] === undefined
                    ? '\u00A0'
                    : formatInvitationLink(invitationCodes[user.id])}
                </Text>
              </ListItemText>
              <ListItemElementRight>
                {invitationCodes[user.id] !== undefined && (
                  <IconButton
                    size="sm"
                    isRound
                    icon={<MdContentCopy />}
                    aria-label={t`Copy to clipboard`}
                    onClick={(): void => {
                      logCopySingleShareLink()
                      const url = formatInvitationLink(invitationCodes[user.id])
                      copy(url)
                      toast({
                        title: t`Copied to clipboard`,
                        description: url,
                      })
                    }}
                  />
                )}
              </ListItemElementRight>
            </ListItem>
          ))}
        </List>
        {isLoading && <PageLoader overlay spinnerDelay={100} />}
      </ModalBody>
    </ModalContent>
  )
}

export default ShareLinkView
