import analytics from '@capturi/analytics'

export const logSendInvitations = (userCount: number): void =>
  analytics.event('inviteUsers_sendInvitations', {
    userCount,
  })

export const logGenerateShareLinks = (userCount: number): void =>
  analytics.event('inviteUsers_generateShareLinks', {
    userCount,
  })

export const logCopySingleShareLink = (): void =>
  analytics.event('inviteUsers_copySingleShareLink')

export const logCopyAllShareLinks = (): void =>
  analytics.event('inviteUsers_copyAllShareLink')
