import { UserResponse } from 'pages/Organization/types'

export function formatInvitationLink(code: string): string {
  return `https://app.capturi.ai/?invite=${code}`
}

export function generatePreformattedShareLinkOverview(
  users: { id: string; name: string }[],
  invitationCodes: { [key: string]: string },
): string {
  return users
    .flatMap((user) => {
      const code = invitationCodes[user.id]
      return [user.name, code ? formatInvitationLink(code) : '', '']
    })
    .join('\n')
}

export function getSelectedUserIdsFromToggleSingle(
  value: string,
  selectedUserIds: Set<string>,
): Set<string> {
  const newSet = new Set(selectedUserIds)
  if (newSet.has(value)) {
    newSet.delete(value)
  } else {
    newSet.add(value)
  }
  return newSet
}

export function getSelectedUserIdsFromToggleAll(
  users: UserResponse[],
  selectedUserIds: Set<string>,
): Set<string> {
  if (selectedUserIds.size === 0) {
    return new Set(users.map((x) => x.id))
  }

  const allSelected = users.every((x) => selectedUserIds.has(x.id))
  if (allSelected) {
    // subtractive
    const newSet = new Set(selectedUserIds)
    users.forEach((x) => {
      newSet.delete(x.id)
    })
    return newSet
  }
  // additive
  return new Set([...selectedUserIds].concat(users.map((x) => x.id)))
}
