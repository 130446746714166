import request, { generateImageUrl } from '@capturi/request'
import {
  Button,
  List,
  ListItem,
  ListItemElementLeft,
  ListItemElementRight,
  ListItemText,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
  PageLoader,
  useToast,
} from '@capturi/ui-components'
import {
  Avatar,
  Box,
  BoxProps,
  Checkbox,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans, plural, t } from '@lingui/macro'
import React from 'react'
import { FiLink } from 'react-icons/fi'
import { MdClose, MdSearch } from 'react-icons/md'

import { logSendInvitations } from './events'
import { useUserInvitationContext } from './useUserInvitation'
import {
  getSelectedUserIdsFromToggleAll,
  getSelectedUserIdsFromToggleSingle,
} from './utils'

type Props = {
  onGoToShareLinkView: () => void
} & BoxProps &
  Pick<ModalProps, 'onClose'>

const SendInvitationsView: React.FC<Props> = (props) => {
  const {
    users,
    filteredUsers,
    selectedUserIds,
    setSelectedUserIds,
    filter,
    setFilter,
    clearFilter,
  } = useUserInvitationContext()
  const toast = useToast()
  const [isSendingInvitations, setIsSendingInvitations] = React.useState(false)

  const onSubmit = async (): Promise<void> => {
    if (selectedUserIds.size === 0) return
    logSendInvitations(selectedUserIds.size)
    try {
      setIsSendingInvitations(true)
      await request.post('authentication/organization/invite', {
        json: {
          userUids: [...selectedUserIds],
        },
      })
      setIsSendingInvitations(false)
      toast({
        title: t`Invitations sent`,
        description: plural(selectedUserIds.size, {
          one: 'E-mail invitations has been sent out to # user',
          other: 'E-mail invitations has been sent out to # users',
        }),
        status: 'success',
      })
      props.onClose?.()
    } catch (_e) {
      toast({
        title: t`Sending invitations failed`,
        description: t`An error occurred while sending the invitations. Please try again.`,
        status: 'error',
      })
    }
  }

  const handleToggle = (
    value: string,
    e: React.MouseEvent<HTMLElement>,
  ): void => {
    e.preventDefault()
    const newSet = getSelectedUserIdsFromToggleSingle(value, selectedUserIds)
    setSelectedUserIds(newSet)
  }

  const handleToggleAll = (): void => {
    const newSet = getSelectedUserIdsFromToggleAll(
      filteredUsers,
      selectedUserIds,
    )
    setSelectedUserIds(newSet)
  }

  return (
    <ModalContent>
      <ModalHeader>
        <Trans>Send invite</Trans>
      </ModalHeader>
      <ModalCloseButton />
      <Box px={6}>
        <InputGroup size="md" mb={4}>
          <InputLeftElement pointerEvents="none">
            <Icon fontSize="14px" as={MdSearch} />
          </InputLeftElement>
          <Input
            placeholder={t`Search for users by name...`}
            borderColor="gray.200"
            value={filter}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              setFilter(event.target.value)
            }
          />
          {filter.length > 0 && (
            <InputRightElement cursor="pointer">
              <Icon fontSize="14px" as={MdClose} onClick={clearFilter} />
            </InputRightElement>
          )}
        </InputGroup>
        <Box>
          <Flex justify="space-between">
            <Heading>
              <Trans>Users ({users.length})</Trans>
            </Heading>
            <Box color="textMuted" fontSize="sm">
              <Link onClick={handleToggleAll}>
                {selectedUserIds.size === users.length ? (
                  <Trans>Deselect all</Trans>
                ) : (
                  <Trans>Select all</Trans>
                )}
              </Link>
            </Box>
          </Flex>
        </Box>
      </Box>
      <ModalBody pt={0}>
        <List>
          {filteredUsers.map((user) => (
            <ListItem
              key={user.id}
              button
              onClick={(e) => handleToggle(user.id, e)}
            >
              <ListItemElementLeft>
                <Avatar
                  size="sm"
                  src={generateImageUrl(user.profileImage?.key, { size: 32 })}
                  name={user.name}
                />
              </ListItemElementLeft>
              <ListItemText as="div">
                <Text as="div" noOfLines={1} wordBreak="break-all">
                  {user.name}
                </Text>
                <Text
                  as="div"
                  noOfLines={1}
                  wordBreak="break-all"
                  color="textMuted"
                  fontSize="xs"
                >
                  {user.email}
                </Text>
              </ListItemText>
              <ListItemElementRight>
                <Checkbox
                  value={user.id}
                  isChecked={selectedUserIds.has(user.id)}
                  onChange={(e) => {
                    e.stopPropagation()
                  }}
                />
              </ListItemElementRight>
            </ListItem>
          ))}
        </List>
      </ModalBody>

      <ModalFooter>
        <Stack isInline spacing={4} justify="space-between" w="100%">
          <Box>
            <IconButton
              size="sm"
              isRound
              icon={<FiLink />}
              aria-label={t`Generate invitation links for selected users`}
              onClick={props.onGoToShareLinkView}
              isDisabled={selectedUserIds.size === 0}
            />
          </Box>
          <Stack isInline spacing={4} justify="flex-end">
            <Button onClick={props.onClose} size="sm">
              <Trans>Cancel</Trans>
            </Button>
            <Button
              primary
              onClick={onSubmit}
              size="sm"
              isDisabled={selectedUserIds.size === 0 || isSendingInvitations}
            >
              <Trans>Send invitations</Trans>
              {selectedUserIds.size > 0 && (
                <Text ml={1}>({selectedUserIds.size})</Text>
              )}
            </Button>
          </Stack>
        </Stack>
      </ModalFooter>
      {isSendingInvitations && <PageLoader overlay spinnerDelay={100} />}
    </ModalContent>
  )
}

export default SendInvitationsView
